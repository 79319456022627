(function () {

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      }

      function deleteAllCookies() {
        var cookies = document.cookie.split(";");

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      window.deleteAllCookies = deleteAllCookies;

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');

        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
        }
        return "";
      }

    if(document.readyState !== 'loading') {
        preinit();
    } else {
        document.addEventListener('DOMContentLoaded', (e) => {
            preinit();
        });
    }

    function preinit() {
        console.log('Preinit: Waiting for scripts');
        const interval = setInterval(
            () => {
                if (
                    typeof jQuery !== 'undefined' &&
                    typeof anime !== 'undefined'
                ) {
                    console.log('Preinit complete');
                    clearInterval(interval);
                    loadJqueryPlugins();
                }
            }, 33
        );
    }

    function loadJqueryPlugins() {
        console.log('Loading plugins');

        const plugins = [
            jsPath + 'jquery.waypoints.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/jquery-touch-events/2.0.3/jquery.mobile-events.js'
        ];

        let numberOfScriptsToLoad = plugins.length;

        for(let i = 0; i < plugins.length; i++) {
            (function () {
                const index = i;
                let script = document.createElement('script');
                script.defer = true;
                script.onload = (e) => {
                    console.log('script loaded', plugins[index]);
                    numberOfScriptsToLoad--;
                    if(numberOfScriptsToLoad <= 0) {
                        console.log('Plugins loaded');
                        init();
                    }
                }
                script.src = plugins[i];
                document.head.appendChild(script);

            })();
        }
    }

    const hash = window.location.hash;
    if (hash) {
        const target = document.getElementById(hash.substring(1));
        if (target) {
            setTimeout(
                function() {
                    window.scrollTo(0, 0);
                    $('html, body').animate({
                        scrollTop: $(target).offset().top
                    }, 400);
                }, 16
            );
        }
    }

    function hideMenuOnScroll() {
        (function () {
            let lastScrollTop = 0;

            window.addEventListener("scroll", () => {
                const pos = window.pageYOffset || document.documentElement.scrollTop;

                if(pos > 800) {
                    document.querySelector('header').classList.add("scrolled");
                } else {
                    document.querySelector('header').classList.remove("scrolled");
                }

                if (pos > lastScrollTop && pos > 240) {
                    document.querySelector('header').classList.add("hidden");
                } else {
                    document.querySelector('header').classList.remove("hidden");
                }
                lastScrollTop = pos;
            });
        })();
    }

    function initAccordions() {
        const elements = document.querySelectorAll('.js-toggle-accordion .handle');

        const toggleFirstElement = () => {
            const accordion = elements[0].closest('.js-toggle-accordion');
            const button = $(accordion).find('.accordion-button');

            button.attr('aria-expanded', 'true');
            $(accordion).addClass('open');
        };

        if(elements.length > 0) {
            toggleFirstElement();
        }

        if (elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];

                element.addEventListener('click',
                    (event) => {
                        event.preventDefault();

                        const target = event.target;
                        const accordion = target.closest('.js-toggle-accordion');
                        const button = $(accordion).find('.accordion-button');

                        if(button.attr('aria-expanded') == 'true') {
                            button.attr('aria-expanded', 'false');
                        } else {
                            button.attr('aria-expanded', 'true');
                        }

                        $(accordion).find('.content').slideToggle();
                        $(accordion).toggleClass('open');
                    }
                );

            }
        }


    }

    function initAnime(targets = '.logo span') {
        anime({
            targets: targets,
            translateY: [-10, 10],
            delay: anime.stagger(100),
            direction: 'alternate',
            loop: true
        });
    }

    function initWaypoint() {
        $('.element').waypoint(
            function() {
                anime({
                    targets: this.element,
                    translateY: [0, 0],
                    opacity: 1,
                    duration: 1000,
                    easing: 'easeInOutExpo'
                });
                this.destroy()
            }, {
                offset: '100%'
            }
        );
    }

    function initCarousels() {
        //const carousels = $('.areas-carousel-module, .buildings-carousel-module, .media-carousel-module, .double-image-carousel-module');
        const carousels = $('.pageposts-feed-module, .areas-carousel-module, .buildings-carousel-module, .media-carousel-module, .double-image-carousel-module');

        if(carousels.length > 0) {
            carousels.each(
                (index, el) => {

                    let onUpdate = null;

                    if($(el).hasClass('media-carousel-module')) {
                        onUpdate = (index) => {
                            $(el).find('.js-image-index').html(index);
                        }
                    }

                    if($(el).hasClass('double-image-carousel-module')) {
                        onUpdate = (index) => {
                            $(el).find('.js-image-index').html(index);
                        }
                    }

                    initCarousel(el, onUpdate, () => {
                        const w = document.body.clientWidth;
                        const cardWidth = $(el).find('.carousel-item').outerWidth();

                        const cardsPerScreen = Math.floor(w / cardWidth);

                        return cardsPerScreen;
                    });
                }
            );
        }
    }

    function initAsLinks() {
        $('.js-as-link').each(
            function (index, el) {
                $(el).click(
                    function (e) {
                        e.preventDefault();
                        window.location.href = $(this).attr('data-href');
                    }
                )

                $(el).find('a').click(
                    function (e) {
                        e.stopPropagation();
                    }
                );
            }
        );
    }

    function initObjectListFiltering() {
        const $object_list = $('.available-object-feed-full-module');
        if($object_list.length <= 0) {
            return;
        }

        $('select[name="location"]').change(
            function () {
                const selected = $('select[name="location"] option:selected').val();

                if(selected == 'all') {
                    $('li.object').removeClass('hidden');
                    return;
                }

                $('li.object').addClass('hidden');
                $('li.object[data-location="' + selected + '"]').removeClass('hidden');

                let count = 0;

                $('li.object').each(
                    function (index, element) {
                        if ($(element).is(":visible")) {
                            count++;
                        }
                    }
                );

                $('.js-num-objects').html(count);
            }
        );

    }

    function initMenu() {

        //add aria-expanded to all menu items
        $('.js-open-menu').attr('aria-expanded', false);
        $('.js-open-menu').attr('aria-controls', 'menu-overlay-id');

        $('body').click(
            function (e) {
                if(menuOpen) {
                    menuOpen = false;
                    $('body').removeClass('menu-open');
                    $('.mobile-menu').removeClass('open');
                    $('.js-open-menu').attr('aria-expanded', false);
                }
        1 });

        $('.menu-overlay').click(
            function (e) {
                e.stopPropagation();
            }
        );

        $('.mobile-menu .hamburger').click(
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                const menuId = $(this).attr('data-id');

                menuCount = 0;
                menuOpen = true;
                $('body').addClass('menu-open');

                loadMenu(menuId, (menu) => {
                    $('.menu-overlay .menu .menu-content').empty();
                    renderMenu(menu);
                });

                let allCloseButtons = $('.js-close-menu').toArray();

                allCloseButtons.forEach((closeButton) => {
                    $(closeButton).click(
                        function (e) {
                            e.preventDefault();

                            menuOpen = false;
                            $('body').removeClass('menu-open');
                            $('.mobile-menu').removeClass('open');
                            $('.js-open-menu').attr('aria-expanded', false);
                        }
                    );
                });
            }
        );

        $('.desktop-menu .js-open-menu').click(
            function (e) {
                e.stopPropagation();
                e.preventDefault();
                const menuId = $(this).attr('data-id');
                const autoselect = $(this).attr('data-autoselect');

                menuCount = 0;
                menuOpen = true;
                $('body').addClass('menu-open');
                $(this).attr('aria-expanded', true);

                loadMenu(menuId, (menu) => {
                    $('.menu-overlay .menu .menu-content').empty();
                    renderMenu(menu);

                    if(autoselect != "") {
                        const firstColumn = $('.menu-overlay .menu .menu-content .menu-column').first().find('.menu-item').each(
                            function (index, element) {
                                if(index + 1 == parseInt(autoselect, 10)) {
                                    $(element).trigger('click');
                                }
                            }
                        );
                    }
                });

                // Close menu on click after menu column is rendered
                $('.menu').on('click', '.js-close-menu', function() {
                    e.preventDefault();
                    menuOpen = false;

                    $('.menu').addClass('slide-menu-out');
                    setTimeout(function() {
                        $('.menu').removeClass('slide-menu-out');
                        $('body').removeClass('menu-open');
                        $('.mobile-menu').removeClass('open');
                        $('.js-open-menu').attr('aria-expanded', false);
                    }, 500);
                })

                // Close menu on overlay click
                $('.menu-overlay').on('click', function(e) {
                    const isInboundClick = !!$(e.target).closest(".menu").length
                    if (isInboundClick) {
                        return
                    }

                    e.preventDefault();
                    menuOpen = false;

                    $('.menu').addClass('slide-menu-out');
                    setTimeout(function() {
                        $('.menu').removeClass('slide-menu-out');
                        $('body').removeClass('menu-open');
                        $('.mobile-menu').removeClass('open');
                        $('.js-open-menu').attr('aria-expanded', false);
                    }, 500);
                });
            }
        );

        $('.menu-overlay .menu .menu-content').on('click', '.js-mobile-menu-backbutton',
            function(event) {
                event.stopPropagation();
                event.preventDefault();

                const thisMenu = $(this).closest('.menu-column');
                const targetIndex = parseInt(thisMenu.attr('data-index'), 10) - 1;
                let popped = false;

                $('.menu-overlay .menu .menu-content .menu-column').each(
                    function (i, element) {
                        const index = parseInt($(element).attr('data-index'), 10);
                        if(index == targetIndex) {
                            return;
                        }

                        if(index > targetIndex) {
                            $(element).remove();
                            popped = true;
                        }
                    }
                );


            }
        );

        $('.menu-overlay .menu').on('click', '.js-open-submenu',
            function(event) {
                event.stopPropagation();
                event.preventDefault();

                if(submenuHoverHandle) {
                    clearTimeout(submenuHoverHandle);
                    submenuHoverHandle = null;
                }

                const menuId = $(this).attr('data-id');
                const thisMenu = $(this).closest('.menu-column');
                thisMenu.addClass('option-selected');
                thisMenu.find('a').attr('aria-expanded', false);
                thisMenu.find('a').removeClass('active');

                $(this).addClass('active');
                $(this).attr('aria-expanded', true);
                const thisLabel = $(this).text();

                const targetIndex = parseInt(thisMenu.attr('data-index'), 10);
                let popped = false;

                $('.menu-overlay .menu .menu-content .menu-column').each(
                    function (i, element) {
                        const index = parseInt($(element).attr('data-index'), 10);

                        if(index == targetIndex) {
                            return;
                        }

                        if(index > targetIndex) {
                            $(element).remove();
                            popped = true;
                        }
                    }
                );

                if(popped) {
                    menuCount = targetIndex + 1;
                }

                loadMenu(menuId, (menu) => {
                    renderMenu(menu, thisLabel);
                });
            }
        );

        let submenuHoverEl = null;
        let submenuHoverHandle = null;

        if (!$.isTouchCapable()) {

            $('.menu-overlay .menu').on('mouseover', '.js-open-submenu',
                function(event) {
                    submenuHoverEl = $(this);

                    if(submenuHoverHandle) {
                        clearTimeout(submenuHoverHandle);
                        submenuHoverHandle = null;
                    }

                    submenuHoverHandle = setTimeout(
                        function () {
                            submenuHoverHandle = null;
                            $(submenuHoverEl).trigger('click');
                        }, 300
                    );

                }
            );

            $('.menu-overlay .menu').on('mouseout', '.js-open-submenu',
                function(event) {
                    if(submenuHoverHandle) {
                        clearTimeout(submenuHoverHandle);
                        submenuHoverHandle = null;
                    }
                }
            );

        }
    }

    function initSearch() {

        const menuSearchInput = document.querySelector('nav .search input');
        const clearInputButton = document.querySelector('nav .js-search-clear');

        $(menuSearchInput).on('focus', (e) => {
            $(menuSearchInput).attr('placeholder', '');

            $('nav .search').addClass('active');
            $(clearInputButton).css('display', 'inline');
        });


        $(clearInputButton).on('click', (e) => {
            menuSearchInput.value = '';

            $(menuSearchInput).attr('placeholder', 'Sök plats, kontakt, nyhet');
            $(clearInputButton).css('display', 'none');
        });

        $(menuSearchInput).on('blur', (e) => {
            if($(menuSearchInput).val() === '') {
                $(menuSearchInput).attr('placeholder', 'Sök plats, kontakt, nyhet');
                $('nav .search').removeClass('active');
                $(clearInputButton).css('display', 'none');
            }
        });

        $('.menu-overlay .menu').on('keydown', '.js-mobile-search',
            function (e) {
                if(e.key == 'Enter') {
                    const searchVal = $(this).val();
                    if(searchVal == "") {
                        return;
                    }
                    window.location.href = "/?s=" + searchVal;
                }
            }
        );

        $('nav .search input').keydown(
            function (e) {
                if(e.key == 'Enter') {
                    const searchVal = $(this).val();
                    if(searchVal == "") {
                        return;
                    }
                    window.location.href = "/?s=" + searchVal;
                }
            }
        );

    }

    let mediaCarouselPopupElement = null;
    let mediaCarouselIndex = 0;
    let mediaCarouselData = null;

    function initMediaCarouselPopupControls($parentCarousel) {
        mediaCarouselPopupElement = $parentCarousel.find('.media-carousel-popup');

        function next(event) {
            event.stopPropagation();
            event.preventDefault();

            mediaCarouselIndex++;
            if(mediaCarouselIndex >= mediaCarouselData.length) {
                mediaCarouselIndex = 0;
            }

            showMediaCarouselImage(mediaCarouselIndex);
        }

        function prev(event) {
            event.stopPropagation();
            event.preventDefault();

            mediaCarouselIndex--;
            if(mediaCarouselIndex < 0) {
                mediaCarouselIndex = mediaCarouselData.length - 1;
            }

            showMediaCarouselImage(mediaCarouselIndex);
        }

        mediaCarouselPopupElement.on('swiperight', prev);
        mediaCarouselPopupElement.on('swipeleft', next);
        mediaCarouselPopupElement.find('.button.next').click(next);
        mediaCarouselPopupElement.find('.button.prev').click(prev);
    }

    function showMediaCarouselImage(index) {
        const description = mediaCarouselData[index].description;
        const image = mediaCarouselData[index].image;
        const byline = mediaCarouselData[index].byline;

        mediaCarouselPopupElement.find('.js-image-index').html(index + 1);
        mediaCarouselPopupElement.find('.js-num-images').html(mediaCarouselData.length);
        mediaCarouselPopupElement.find('.js-image-description').html(description);
        mediaCarouselPopupElement.find('.js-byline').html(byline);
        mediaCarouselPopupElement.find('img').attr('src', image);
    }

    function initMediaCarousel() {
        const $mediaCarousel = $('.media-carousel-module');
        if($mediaCarousel.length > 0) {

            initMediaCarouselPopupControls($mediaCarousel);

            $('.media-carousel-popup .close').click(
                function (event) {
                    event.stopPropagation();
                    event.preventDefault();
                    $('body').removeClass('media-carousel-popup-open');
                }
            );

            $mediaCarousel.find('.js-media-carousel-show-map').click(
                function () {
                    $('body').addClass('media-carousel-popup-map-open');
                }
            );

            $mediaCarousel.find('.media-carousel-popup-map .close').click(
                function () {
                    $('body').removeClass('media-carousel-popup-map-open');
                }
            );

            $mediaCarousel.find('.js-expand-image').click(
                function (event) {
                    event.preventDefault();
                    event.stopPropagation();

                    mediaCarouselData = [];

                    $mediaCarousel.find('.media-carousel-item a').each(
                        function (i, el) {
                            const imageUrl = $(el).attr('data-expand-image');
                            const description = $(el).attr('data-description');
                            const byline = $(el).attr('data-byline');

                            mediaCarouselData.push({
                                image: imageUrl,
                                description: description,
                                byline: byline
                            });
                        }
                    );

                    mediaCarouselIndex = parseInt($(this).attr('data-index'), 10);
                    showMediaCarouselImage(mediaCarouselIndex);
                    $('body').addClass('media-carousel-popup-open');
                }
            );
        }
    }

    function initAnnouncement() {

        $('.announcment a.close').click(
            function (e) {
                e.preventDefault();
                $('body').removeClass('announcment-open');
                setCookie('announcement', window.announcementHash, 7);
            }
        );

        $('.announcment-full a.close').click(
            function (e) {
                e.preventDefault();
                $('body').removeClass('announcment-full-open');
                setCookie('announcement_full', window.announcementHash, 7);
            }
        );

        $('.announcment-full a.announcement-full-follow').click(
            function () {
                setCookie('announcement_full', window.announcementHash, 7);
                window.location.href = $(this).attr('href');
            }
        );
    }

    function initAnchorLinksSticky() {
        const anchorLinksModule = $('.anchor-links-module').get(0);
        const anchorLinksInner = $('.anchor-links-module > .container').get(0);
        if(!anchorLinksModule) {
            return;
        }

        const headerElement = $('header').get(0);

        $(window).scroll(
            function () {

                const anchorLinksRect = anchorLinksInner.getBoundingClientRect();
                const headerRect = headerElement.getBoundingClientRect();
                const anchorLinksTop = anchorLinksRect.y;
                const headerTop = headerRect.y + headerRect.height;

                if(anchorLinksTop < headerTop) {
                    headerElement.classList.add('with-anchor-links');
                    anchorLinksModule.classList.add('stuck');
                } else {
                    headerElement.classList.remove('with-anchor-links');
                    anchorLinksModule.classList.remove('stuck');
                }
            }
        );

    }

    function initCookieBar() {
        const cookiebarState = getCookie("read_cookiebar");

        if(cookiebarState === "") {
            $('.cookie-bar').addClass('open');
        }

        $('.js-close-cookiebar').click(
            function (e) {
                e.preventDefault();
                setCookie('read_cookiebar', "1", 2);
                $('.cookie-bar').removeClass('open');
            }
        );
    }

    function initIntersectionObserver () {
        const handleTocIntersection = (entries) =>
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionId = $(entry.target).data('section-id');
                    $('.anchor-link').removeClass('anchor-link--active');
                    $(`.anchor-link[data-section-id=${sectionId}]`).addClass('anchor-link--active');
                    $(`.anchor-link[data-section-id=${sectionId}]`).get(0)?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                    });
                }
            });

        const options = {
            rootMargin: '-150px 0px -300px 0px',
        };
        const tocIntersectionObserver = new IntersectionObserver(handleTocIntersection, options);

        $('.page-section').map((i, section) =>
            tocIntersectionObserver.observe(section)
        );
    };

    function init() {
        hideMenuOnScroll();
        initAccordions();
        initCookieBar();
        initAsLinks();
        initAnime();
        initSearch();
        initWaypoint();
        initCarousels();
        initObjectListFiltering();
        initMenu();
        initMediaCarousel();
        initAnnouncement();
        initAnchorLinksSticky();
        initAutocompleteSearchForm();
        initToggleModal();
        initIntersectionObserver();
        scrollTo();

        // Set deley to 500ms to make sure hotjar scripts are loaded
        setTimeout(initFocusInput, 500);
    }
})();

function initCarousel(selector, onUpdateFn, getNumCardsPerScreen) {
    const $carousel = $(selector);
    const $carouselInner = $carousel.find('.carousel-inner');
    const $progressbar = $carousel.find('.js-progress-bar'); 
    let cardsPerScreen = getNumCardsPerScreen();
    let lastCardsPerScreen = cardsPerScreen;
    let curPos = 0;
    let numCards = $carousel.find('.carousel-item').length;
    // resize handler
    jQuery(window).resize(function () {
        var n = getNumCardsPerScreen();
        if (n != lastCardsPerScreen) {
            cardsPerScreen = lastCardsPerScreen = getNumCardsPerScreen();
            resetCarousel();
        } else {
            updateCarousel();
        }
    });

    $carousel.find('.button.prev').click(
        function (e) {
            e.preventDefault();
            if (curPos == 0) {
                return;
            }
            curPos--;
            updateCarousel();
        }
    );

    $carousel.find('.button.next').click(
        function (e) {
            e.preventDefault();
            if (curPos == (numCards - cardsPerScreen)) {
                return;
            }
            curPos++;
            updateCarousel();
        }
    );

    if ($.isTouchCapable()) {

        $carousel.on('swiperight',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                if (curPos == 0) {
                    return;
                }
                curPos--;
                updateCarousel();
            }
        );

        $carousel.on('swipeleft',
            function (e) {
                e.preventDefault();
                e.stopPropagation();
                if (curPos == (numCards - cardsPerScreen)) {
                    return;
                }
                curPos++;
                updateCarousel();
            }
        );
    }

    function updateCarousel() {
        var step = $carouselInner.find('.carousel-item').outerWidth();
        var translateX = -(step * curPos) | 0;
        var innerWidth = $carouselInner.outerWidth();
        var cardsWidth = (numCards * step);
        var maxTranslate = cardsWidth - innerWidth;

        if(translateX < -maxTranslate) {
            translateX = -maxTranslate;
        }

        onUpdateFn && onUpdateFn(curPos + 1);

        $carouselInner.css('transform', 'translateX(' + translateX + 'px)');

        if (curPos == 0) {
            $carousel.find('.button.prev').addClass('disabled');
            $carousel.find('.button.prev').attr('aria-disabled', 'true');

        } else {
            $carousel.find('.button.prev').removeClass('disabled');
            $carousel.find('.button.prev').attr('aria-disabled', 'false');
        }

        if (curPos == (numCards - cardsPerScreen)) {
            $carousel.find('.button.next').addClass('disabled');
            $carousel.find('.button.next').attr('aria-disabled', 'true');
        } else {
            $carousel.find('.button.next').removeClass('disabled');
            $carousel.find('.button.next').attr('aria-disabled', 'false');

        }

        let progress = ((curPos + 1) / (numCards - cardsPerScreen + 1)) * 100;
        $carousel.find('.js-progress-bar').attr('value', progress);
    }

    function resetCarousel() {
        curPos = 0;
        updateCarousel();
    }
}

let menuCount = 0;
let menuOpen = false;

function loadMenu(id, callbackFn) {

    const item = getCachedMenuObjectById(id);

    if(!item) {
        return;
    }

    if(item) {
        callbackFn && callbackFn(item);
        return;
    }

    $.ajax({
        url: window.ajaxurl,
        data: {
            action: 'render_menu',
            menu_id: id,
        },
        dataType: 'JSON',
        cache: false,
        method: 'POST',
        success: function (res) {
            callbackFn && callbackFn(res);
        }
    });

}

function getCachedMenuObjectById(menuId) {
    for(let i = 0; i < window.amfMenuPosts.length; i++) {
        const item = window.amfMenuPosts[i];
        if (item.id == menuId) {
            return item.data;
        }
    }
    return null;
}

function renderMenu(menu, prevLabel) {

    function convert_link_to_relative(url) {

        const domains = [
            'stage.amfffastigheter.se',
            'amffastigheter.se'
        ];

        for(let j = 0; j < domains.length; j++) {

            if (url.indexOf(domains[j]) > -1) {

                const domainPath = url.split('//');

                const urlPath = domainPath[1].split('/');

                let ret = "";
                for(let i = 1; i < urlPath.length; i++) {
                    ret += "/" + urlPath[i];
                }

                return ret;
            }
        }

        return url;
    }

    let html = `<div class="menu-column" data-index="${menuCount}" id="column-id-${menuCount}">`;

    let mobileMenuAddons = `
    <input type="text" class="js-mobile-search" placeholder="Sök plats, kontakt, nyhet" />
    <svg class="looking-glass" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9651 4.11019C19.2261 7.3352 19.2261 12.564 15.9651 15.789C12.7041 19.014 7.41702 19.014 4.15603 15.789C0.895051 12.564 0.895051 7.3352 4.15603 4.11019C7.41702 0.885177 12.7041 0.885177 15.9651 4.11019ZM17.6299 16.5043C21.097 12.5961 20.9452 6.6433 17.1745 2.91417C13.2456 -0.971389 6.87557 -0.971388 2.94667 2.91417C-0.982224 6.79972 -0.982224 13.0994 2.94667 16.985C6.62615 20.6239 12.4466 20.8549 16.3979 17.678L22.7905 24H23.9998V22.804L17.6299 16.5043Z" fill="white"></path>
    </svg>
    <a href="#" class="js-mobile-menu-backbutton back-button">
    <svg class="arrow" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1722_5840)">
            <path d="M23.1988 11.648L22.8508 11.3L12.0508 0.5L10.8508 1.7L20.7988 11.648L0.902782 11.648L0.050782 12.5L0.902782 13.352L20.7988 13.352L10.8508 23.3L12.0508 24.5L22.8508 13.7L23.1988 13.352L24.0508 12.5L23.1988 11.648Z" fill="white"></path>
        </g>
        <defs>
            <clipPath id="clip0_1722_5840">
                <rect width="16.9706" height="16.9706" fill="white" transform="translate(12.0508 0.5) rotate(45)"></rect>
            </clipPath>
        </defs>
    </svg>
    <span>${prevLabel}</span>
    </a>`;

    html += mobileMenuAddons;

    for(let i = 0; i < menu.length; i++) {
        const item = menu[i];

        switch(item.item_type) {

            case 'image':
                html += `<div class="image menu-item">
                    <img src="${item.image.url}">
                </div>`;
            break;

            case 'header':
                html += `
                    <div class="menu-link-header menu-item ${item.spaced && `spaced`}">
                        <label class="element-label">${item.label}</label>
                        ${item.header_link && `<a href="${ convert_link_to_relative(item.header_link.url)}">${item.header_link.title}</a>`}
                    </div>
                `;
            break;

            case 'link':
                html += `<a href="${convert_link_to_relative(item.link.url)}" class="menu-link menu-item ${item.spaced && `spaced`}"><span>${item.label}</span></a>`;
            break;

            case 'avLink':
                html += `<a href="${convert_link_to_relative(item.link.url)}" class="menu-link menu-item ${item.spaced && `spaced`}"><span>${item.label} <b>${item.availability_count}</b></span></a>`;
            break;

            case 'submenu':
                html += `<a href="#" class="menu-link js-open-submenu menu-item ${item.spaced && `spaced`}" data-id="${item.submenu.ID}" aria-expanded="false" aria-controls="column-id-${menuCount+1}">
                            <span>${item.label}</span>
                            <svg class="arrow" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1722_5840)">
                                <path d="M23.1988 11.648L22.8508 11.3L12.0508 0.5L10.8508 1.7L20.7988 11.648L0.902782 11.648L0.050782 12.5L0.902782 13.352L20.7988 13.352L10.8508 23.3L12.0508 24.5L22.8508 13.7L23.1988 13.352L24.0508 12.5L23.1988 11.648Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1722_5840">
                                        <rect width="16.9706" height="16.9706" fill="white" transform="translate(12.0508 0.5) rotate(45)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>`;
            break;
        }
    }

    html +=`<a href="#" class="close close-desktop js-close-menu" aria-label="<?= __('Close menu', 'amf'); ?>">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.2207" y="0.129761" width="20.7173" height="1.72644" transform="rotate(45 1.2207 0.129761)" fill="white" />
    <rect x="16" y="1.22083" width="20.7173" height="1.72644" transform="rotate(135 16 1.22083)" fill="white" />
    </svg>
    </a></div>`;

    menuCount++

    $('.menu-overlay .menu .menu-content').append(html);

}

function initAutocompleteSearchForm() {

    const searchField = $('#estate-search-input');

    if(searchField) {
        $('#estate-search-input').on('focus', () => {
            searchField.attr('placeholder', '');
        });

        $('#estate-search-input').on('blur', () => {
            if(searchField.val()=== '') {
                searchField.attr('placeholder', 'Sök efter namn eller adress');
            }
        });
    }

    if($('#estate-search-input')[0]) {
        $('#estate-search-input').autocomplete({
            source: function(request, response) {
                $.ajax({
                    url: '/wp/wp-admin/admin-ajax.php',
                    dataType: 'json',
                    data: {
                        action: 'autocomplete_search',
                        term: request.term
                    },
                    success: function(data) {
                        // Map through the data and highlight the search term
                        var highlightedData = $.map(data, function(item) {
                            var term = request.term;
                            var regex = new RegExp('(' + $.ui.autocomplete.escapeRegex(term) + ')', 'gi');
                            var label = item.value.replace(regex, '<strong>$1</strong>');
                            return {
                                label: label,
                                value: item.value,
                                link: item.link
                            };
                        });
                        response(highlightedData);
                    }
                });
            },
            minLength: 0,
            delay: 100,
            select: function(event, ui) {
                // Redirect to the selected page
                window.location.href = ui.item.link;
            },
        }).autocomplete("instance")._renderItem = function(ul, item) {
            // Ensure HTML is rendered
            return $("<li>")
                .append($("<div>").html(item.label))
                .appendTo(ul);
        };

        $('#estate-search-form').submit(function(event) {
            // Prevent the default form submission
            event.preventDefault();
        });
    }

    // Re add if all items should be shown on focus
    // $('#estate-search-input').click(function() {
    //     $(this).autocomplete('search', '');
    // });
}

function initToggleModal() {
    $('.js-toggle').click(function(e){
        e.preventDefault();
        var $button = $(this);
        var modalId = $button.data('modal-id');
        var modal = $('#modal-' + modalId);

        //event for Contact to Matomo
        const mtm_datalayer = dataLayer;

        if(modalId && mtm_datalayer) {
            mtm_datalayer.push({'event': 'contact_lessor_click'});
        }

        window.addEventListener('message', function(event) {
            if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                mtm_datalayer.push({
                    event: 'contact_lessor_sent',
                    hsFormGuid: event.data.id,
                    currentUrl: window.location.href,
                });
            }
        });

        modal.addClass('js-slide-in');
        $('body').addClass('js-modal-open');

        // Move focus to the close button on tab
        modal.find('.js-modal-close').focus();
        $button.addClass('js-focus-state');
    });

    $('.js-modal-close, .js-modal-overlay').click(function(e){
        var modal = $('.js-modal');
        $('body').removeClass('js-modal-open');

        // Set focus
        $('.js-focus-state').focus();

        modal.addClass('js-slide-out');
        setTimeout(function() {
            modal.removeClass('js-slide-in');
            modal.removeClass('js-slide-out');
        }, 400);

        // Remove focus state
        $('.js-focus-state').removeClass('js-focus-state');
    });
}

function initFocusInput(){
    // Listen for Hotjar form ready event
    $('.hs-form-field input[type="text"], .hs-form-field input[type="email"], .hs-form-field input[type="tel"], .hs-form-field textarea')
    .on('focus', function(){
        $(this).closest('.hs-form-field').find('label').addClass('focus');
    });

    $('.hs-form-field input, .hs-form-field textarea').on('blur', function() {
        if ($(this).val().trim() === '') {
            $(this).closest('.hs-form-field').find('label').removeClass('focus');
        }
    });
};

function scrollTo() {

    const fromElements = document.querySelectorAll('.js-scroll-to');

    fromElements.forEach(function(element) {
        const scrollId = $(element).data('scroll-id');
        const toElement = document.getElementById(scrollId);

        const scrollToElement = (element, offset = 45, behavior = 'smooth') => {
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;

            const offsetPosition = elementPosition - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior
            });
        };

        $(element).on('click', function() {
            scrollToElement(toElement);
        });
    });
}


